
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { DonViService } from '@/core/services/DonVi.service';
import { PartBaseResponse } from '@/core/interfaces/ApiResponses';
import { setCurrentPageTitle } from '@/core/helpers/breadcrumb';
import { swalErrNotification } from '../../../../core/helpers/utils';

import BoPhanService from '@/core/services/BoPhan.service';

export default defineComponent({
  name: "part-detail",
  setup() {
    const route = useRoute();

    const partDetail = ref<PartBaseResponse>({
      id: -1,
      id_don_vi: -1,
      dia_chi: "",
      ma_bo_phan: "",
      mo_ta: "",
      ten_bo_phan: "",
    });

    const departmentName = ref("");

    onMounted(async () => {
      setCurrentPageTitle("Trang quản lý bộ phận");

      try {
        const {
          data: { data },
        } = await BoPhanService.get(+route.params.id);
        partDetail.value = { ...data };

        const {
          data: {
            data: { ten_don_vi },
          },
        } = await DonViService.fetchCurrentDepartmentById(
          partDetail.value.id_don_vi
        );
        departmentName.value = ten_don_vi;
      } catch(e) {
        swalErrNotification(e, 'Xin lỗi, có vẻ có lỗi xảy khi lấy thông tin bộ phận, mời bạn quay lại sau');
      }
    });

    return {
      partDetail,
      departmentName,
      updateLinkComputed: computed(
        () => `/quan-ly/bo-phan/cap-nhat/${partDetail.value.id}`
      ),
    };
  },
});
